<template>
	<section v-if="reviews.length" class="main-content">
		<div class="small-row row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 review-slider">
				<client-only>
					<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
						<slide v-for="review in reviews" :key="review.ID" class="review-slider-content">
							<p>{{ review.content }}</p>
							<div class="source">
								<div>
									<span class="source-logo">
										<font-awesome-icon :icon="`fa-brands fa-${review.source}`" size="1x" />
									</span>
								</div>
								<div>
									<strong>{{ review.firstName }}</strong>
									<span v-if="review.date">
										{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
									</span>
								</div>
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.row {
	max-width: 980px;
	margin: 0 auto;
}

.review-slider {
	padding: 25px 50px;
	overflow: hidden;

	.carousel {
		padding-bottom: 80px;
	}

	.review-slider-content {
		width: 100% !important;
		display: flex;
		flex-direction: column;

		.source {
			width: auto;
			margin: 10px auto;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;

			div {
				display: flex;
				flex-flow: column wrap;
				justify-content: space-between;
				align-items: flex-start;
				padding: 0 15px;

				&:last-child {
					border-left: 1px solid #938ea4;
				}
			}

			svg {
				font-size: 26px;
			}

			strong {
				font-size: calc($body-font-size + 3px);
				font-weight: 400;
				font-family: $heading-font-family;
				color: $header-color;
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__pagination-item) {
	margin: 0 4px;

	button {
		width: 11px;
		height: 11px;
		border: 1px solid #fff;
		border-radius: 50%;
		background: rgb(239 239 239);

		&.carousel__pagination-button--active {
			background: #222;
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;

		&::before {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
